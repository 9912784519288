html, body, #root{
    width: 100%;
    height: 100%;
    overflow:hidden;
     font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png') 39 39,
    auto;

    /* Disable text and iamge selection */
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  

.braid {
  background: #0891b2;
  background-image: linear-gradient(90deg, #0ea5e9 50%, transparent 0),
    linear-gradient(#0891b2 50%, transparent 0);
  background-size: 0.4px 0.4px;
  box-shadow: 0 0px 35px rgba(0, 0, 0, 0.25);
}

.shadow-under {
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.2);
}
.shadow-inner-custom{
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.3);
}

.anime-forward {
  animation-fill-mode: forwards;
}

.highlight {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, #a5f3fc 50%);
  background-size: 200%;
}

.highlight-red {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, rgba(239, 68, 68, 0.1) 50%);
  background-size: 200%;
}

.highlight-active {
  background-position: -100% 0;
}

.link-pointer {
  cursor: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png') 39 39,
    auto;
}

.contact-button {
  background-color: #dc2626;
  color: white;
}

.fed {
  transition-delay: 2000ms;
}

.rjs {
  transition-delay: 2400ms;
}

.reach-out-highlight {
  transition-delay: 1500ms;
}
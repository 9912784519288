@tailwind base;
@tailwind components;
@tailwind utilities;

.card {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card > div {
  width: 45vh;
  height: 65vh;
  touch-action: none;
  background-color: white;
  will-change: transform;
  border-radius: 32px;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.3), 0 10px 10px -10px rgba(50, 50, 73, 0.2);
}